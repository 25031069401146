<template>
  <div>
    <b-card-group columns>
      <div v-if="$can('view', 'Xero Configuration')">
        <xero-config></xero-config>
      </div>
      <div v-if="$can('view', 'Map Configuration')">
        <map-config></map-config>
      </div>
      <div v-if="$can('view', 'Environment Configuration')">
        <env-config></env-config>
      </div>
    </b-card-group>
  </div>
</template>

<script>
import XeroConfig from "./xero-config/index.vue";
import MapConfig from "./map-config/index.vue";
import EnvConfig from "./env-config/index.vue";
import { BCardGroup } from "bootstrap-vue";
export default {
  components: {
    XeroConfig,
    MapConfig,
    EnvConfig,
    BCardGroup,
  },
};
</script>

<style scoped>
.card-columns {
  width: 150%;
}
</style>
